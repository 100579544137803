var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"about__container"},[_c('h1',[_vm._v("Who am I exactly?")]),_c('div',{staticClass:"about"},[_vm._l((_vm.milestones),function(milestone,index){return _c('article',{key:milestone.date,staticClass:"about__milestone"},[_c('figure',{staticClass:"milestone__properties"},[_c('figcaption',{staticClass:"milestone__info"},[_c('h3',[_vm._v(_vm._s(milestone.title))]),_c('span',[_vm._v(_vm._s(milestone.date))]),_c('span',[_vm._v(_vm._s(milestone.place))])]),_c('img',{staticClass:"milestone__circle",attrs:{"src":require('@/assets/' + milestone.land)}})]),_c('span',{staticClass:"about__line",style:({
          height: _vm.lineLength - 160 + 'px',
          transform:
            index % 2 === 0
              ? `rotate(-${_vm.lineRotation}deg)`
              : `rotate(${_vm.lineRotation}deg)`,
        })})])}),_vm._m(0)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about__notification"},[_c('h2',[_vm._v("Scroll to find out more!")])])
}]

export { render, staticRenderFns }